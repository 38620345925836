import * as Scrivito from 'scrivito';
import { RedirectObject } from './redirect-object-class';
import { RedirectObjectAttributes, RedirectObjectId } from './redirect-object-definitions';
import { ValidationInfo, ValidationSeverityLevel } from '../../utils/type.utils';
import { checkValue } from '../../utils/scrivito/validation';
import { HelperText, Message } from '../../utils/translations';

type ValidationResult = { message: string; severity: ValidationSeverityLevel } | void;

Scrivito.provideEditingConfig(RedirectObjectId, {
  title: 'Redirect',
  description: 'Redirect configuration',
  hideInSelectionDialogs: true,
  properties: [RedirectObjectAttributes.SOURCE, RedirectObjectAttributes.TARGET],
  titleForContent(widget: Scrivito.Widget) {
    const obj = widget as unknown as RedirectObject; // TODO find a better solution
    return 'Redirect: ' + (obj as unknown as RedirectObject).permalink();
  },
  // TODO find out why this is not working anymore
  // descriptionForContent() {
  //   return 'Ziel: ' + obj.get(RedirectObjectAttributes.TARGET);
  // },
  attributes: {
    [RedirectObjectAttributes.TARGET]: {
      title: 'Ziel-URL',
      description: `${HelperText.ABSOLUTE_OR_RELATIVE_PATH} ${HelperText.PERMALINK_SOURCE_URI}`,
    },
  },
  validations: [
    (widget: Scrivito.Widget): ValidationInfo => {
      const obj = widget as unknown as RedirectObject; // TODO find a better solution

      if (!obj.permalink()) {
        return {
          message: Message.PERMALINK_MISSING,
          severity: 'error',
        };
      }

      return undefined;
    },
    [
      RedirectObjectAttributes.TARGET,
      (value: string, { obj }: { obj: RedirectObject }): ValidationResult => {
        // Regular validation for the Source URI
        const result = checkValue(value, Message.TARGET_URI_MISSING);

        // Validate, if the permalink is set.
        if (!obj.permalink()) {
          const previousMessage = result ? result.message : '';
          return {
            message: `${Message.PERMALINK_MISSING}\n${previousMessage}`,
            severity: 'error',
          };
        }

        return undefined;
      },
    ],
  ],
});
