import React from 'react';
import * as Scrivito from 'scrivito';
import styles from '../page-error404/page-error404.module.scss';
import { Error404Attributes } from '../../../pages/home-page/home-page-404';
import { LandingPageTemplate } from './landing-page-template';
import { ReactComponent as Rocket } from '../../../assets/img/rheinbahn-rocket.svg';
import { isPageVisible } from '../../../utils/scrivito/schedule-validation';
import { ContentPage } from '../../../pages/content-page';

export const LandingPageError404: React.FC = Scrivito.connect(() => {
  const root = Scrivito.Obj.root();
  const page = Scrivito.currentPage();
  const scheduleInfo = isPageVisible(page as ContentPage);

  return (
    <LandingPageTemplate scheduleInfo={scheduleInfo}>
      <div>
        {/* Limits the width */}
        <div>
          <div className={styles.PageError404}>
            <h1>404</h1>
            <Scrivito.ContentTag tag={'p'} content={root} attribute={Error404Attributes.TEXT} />
            <Rocket />
          </div>
        </div>
      </div>
      <Scrivito.ContentTag content={root} attribute={Error404Attributes.WIDGETS} />
    </LandingPageTemplate>
  );
});
