import * as Scrivito from 'scrivito';
import { LinkDropdownComponent } from '../../components/controls/link-dropdown/link-dropdown';
import { PageTemplate } from '../../components/pages/page-template/page-template';
import { FaqPage, FaqPageObjectClass } from './faq-page-class';
import { FaqPageAttributes } from './faq-page-definitions';
import styles from './faq-page.module.scss';
import { isPageVisible } from '../../utils/scrivito/schedule-validation';
import { PageError404 } from '../../components/pages/page-error404/page-error404';
import { BasePageAttributes } from '../base-page';

export const FaqPageComponent: React.FC<{ page: FaqPage }> = ({ page }) => {
  const editMode = Scrivito.isInPlaceEditingActive();
  const scheduleInfo = isPageVisible(page);

  if (!editMode && !scheduleInfo.visible) {
    return <PageError404 />;
  }

  const subjectParentPage = page.get(FaqPageAttributes.SUBJECT_PARENT_PAGE);
  const headlineElement = (page.get(FaqPageAttributes.HEADLINE_ELEMENT) as string) ?? 'h2';

  let linkDropdownItems;
  if (subjectParentPage) {
    linkDropdownItems = subjectParentPage.children();
  }

  return (
    <PageTemplate className="FaqPage" scheduleInfo={scheduleInfo}>
      <div>
        <div>
          <div className={styles.HeadlineContainer}>
            <Scrivito.ContentTag content={page} attribute={FaqPageAttributes.HEADLINE} tag={headlineElement} />
            {subjectParentPage && linkDropdownItems ? (
              <LinkDropdownComponent items={linkDropdownItems} currentPage={page} />
            ) : undefined}
          </div>
        </div>
        <div>
          <Scrivito.ContentTag content={page} attribute={BasePageAttributes.BODY} />
        </div>
      </div>
    </PageTemplate>
  );
};

Scrivito.provideComponent(FaqPageObjectClass, FaqPageComponent);
