import React, { LegacyRef } from 'react';
import * as Scrivito from 'scrivito';
import { HelmetProvider } from 'react-helmet-async';
import { StyledEngineProvider } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material';
import { defaultThemeOptions } from './themes/default.theme';
import { PageError404 } from './components/pages/page-error404/page-error404';
import { helmetContext } from './config/helmet-context';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import { ImageScalableProvider } from './components/controls/image/responsive-image/responsive-image-scalable';
import { AppBanner } from './components/building-blocks/app-banner/app-banner.scrivito';
import { PageTrackerContextProvider } from './utils/page-tracker';

function App({ callback }: { callback: LegacyRef<HTMLDivElement> | undefined }): JSX.Element {
  // Load theme
  const theme = createTheme(defaultThemeOptions);
  const locale = process.env.REACT_APP_SITE_ID || 'de';
  dayjs.locale(locale);

  return (
    <div ref={callback}>
      <PageTrackerContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <HelmetProvider context={helmetContext}>
                <ImageScalableProvider>
                  <Scrivito.CurrentPage />
                  <Scrivito.NotFoundErrorPage>
                    <PageError404 />
                  </Scrivito.NotFoundErrorPage>
                  <AppBanner />
                </ImageScalableProvider>
              </HelmetProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </PageTrackerContextProvider>
    </div>
  );
}

export default App;
