import { ScheduleAttributes } from '../../pages/properties/schedule';
import { PressRelease } from '../../pages/press-release';
import { FaqPage } from '../../pages/faq-page';
import { ContentPage } from '../../pages/content-page';
import { formatDate, isSchedulingContentVisible } from '../general.utils';
import { ReactElement } from 'react';
import { HelperText } from '../translations';
import { Alert } from '@mui/material';
import { Obj } from 'scrivito';

export interface ScheduleInfo {
  visible: boolean;
  visibleFrom: Date | null;
  visibleUntil: Date | null;
}

/**
 * Checks if the given scheduling widget has visible content
 * @param page The scheduling widget to check
 */
export const isPageVisible = (
  page: PressRelease | FaqPage | ContentPage
): { visible: boolean; visibleFrom: Date | null; visibleUntil: Date | null } => {
  const pageObj = page as Obj;
  const visibleFrom = pageObj.get(ScheduleAttributes.VISIBLE_FROM) as Date | null;
  const visibleUntil = pageObj.get(ScheduleAttributes.VISIBLE_UNTIL) as Date | null;

  return { visible: isSchedulingContentVisible(visibleFrom, visibleUntil), visibleFrom, visibleUntil };
};

/**
 * Renders the scheduling information when the user is in editing mode
 * @param info the info object if the content is visible or not including
 *  the date from when the content is visible or null and
 *  the date until the content is visible or null
 */
export const renderScheduleInfo = (info: ScheduleInfo): ReactElement => {
  return (
    <Alert severity="warning" className={'ScheduleAlert'}>
      <ul>
        {!info.visible && <li>{HelperText.SCHEDULING_EDITOR_VISIBLE}</li>}
        {info.visibleFrom && (
          <li>{HelperText.SCHEDULING_EDITOR_FROM.replace('$date', formatDate(info.visibleFrom))}</li>
        )}
        {info.visibleUntil && (
          <li>{HelperText.SCHEDULING_EDITOR_UNTIL.replace('$date', formatDate(info.visibleUntil))}</li>
        )}
      </ul>
    </Alert>
  );
};
