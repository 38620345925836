import { SearchResultPageId } from './../pages/search-result-page/search-result-page-definitions';
import * as Scrivito from 'scrivito';
import { DownloadObjectId, IconObjectId, ImageObjectId, RedirectObjectId, VideoObjectId } from '../objects';
import { ContentPageId } from '../pages/content-page';
import { HomePageId } from '../pages/home-page';

// Define the labels and icons for the main filters and nested filters
const FILTER_PRESENTATIONS = {
  [HomePageId]: { title: 'Homepages', icon: 'globe' },
  [ContentPageId]: { title: 'Content Pages', icon: 'sheet' },
  [DownloadObjectId]: { title: 'Files', icon: 'sheet' },
  [IconObjectId]: { title: 'Icons', icon: 'thumbnails' },
  [ImageObjectId]: { title: 'Images', icon: 'images' },
  [VideoObjectId]: { title: 'Videos', icon: 'video' },
  [RedirectObjectId]: { title: 'Redirects', icon: 'link' },
  ZIP: { title: 'ZIP Files', icon: 'zip' },
  PDF: { title: 'PDF Files', icon: 'pdf' },
  TXT: { title: 'TXT Files', icon: 'txt' },
  DOC: { title: 'DOC Files', icon: 'doc' },
  XLS: { title: 'XLS Files', icon: 'xls' },
  PPT: { title: 'PPT Files', icon: 'ppt' },
  CSV: { title: 'CSV Files', icon: 'database' },
} as const;

// The identifiers for the nested page filters
const PAGES = [ContentPageId, HomePageId, SearchResultPageId];
// The identifiers for the nested file extension filters
const FILES = ['zip', 'csv', 'pdf', 'txt', 'doc', 'xls', 'ppt'];

// TODO(node-update): Move to a better place
declare type ContentBrowserFilters = {
  [key: string]: ContentBrowserFilterItem;
};

// TODO(node-update): Move to a better place
declare interface ContentBrowserFilterItem {
  expanded?: boolean;
  field?: string | readonly string[];
  icon?: string;
  operator?: 'contains' | 'containsPrefix' | 'equals' | 'startsWith' | 'isGreaterThan' | 'isLessThan' | 'matches';
  options?: ContentBrowserFilters;
  query?: unknown;
  selected?: boolean;
  title?: string;
  type?: 'tree' | 'radioButton' | 'checkbox';
  value?: unknown;
}

// TODO(node-update): Move to a better place
declare interface FilterContext {
  _validObjClasses?: string[];
}

export function configureScrivitoContentBrowser(): void {
  Scrivito.configureContentBrowser({
    filters: ({ _validObjClasses }: FilterContext) => {
      if (_validObjClasses) {
        switch (_validObjClasses.length) {
          case 0:
            // This is the case if there are no ObjClass Names in _validObjClasses
            return defaultFilters();
          case 1:
            // If there is only one ObjClass name in _validObjClasses, filter for
            // it in a list of known object classes defined below
            return filterForObjClass(_validObjClasses[0]);
          default:
            return filtersForObjClasses(_validObjClasses);
        }
      }

      return defaultFilters();
    },
  });
}

/**
 * creates filters for an array of object classes
 * @param objClasses
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filtersForObjClasses(objClasses: string[]): any {
  return {
    _objClass: {
      options: {
        All: {
          title: 'All',
          icon: 'folder',
          field: '_objClass',
          value: objClasses,
          selected: true,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...objClasses.reduce((result: Record<string, any>, value: any) => {
          result[value] = filterOptionsForObjClass(value);
          return result;
        }, {}),
      },
    },
  };
}

/**
 * Filter for a single Scrivito.Obj class name
 * @param objClass
 * @return an Object with the key _objClass (the filters freely chosen name)
 * and options containing filter options
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterForObjClass(objClass: string): any {
  return {
    _objClass: {
      options: {
        // The name for the nested filter item
        [objClass]: {
          //get the filter options for our current objClass
          ...filterOptionsForObjClass(objClass),
          selected: true,
        },
      },
    },
  };
}

/**
 * A function which generates a bunch of default filters
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defaultFilters(): any {
  return {
    _objClass: {
      options: {
        All: {
          title: 'All',
          icon: 'folder',
          query: Scrivito.Obj.all(),
          selected: true,
        },
        [IconObjectId]: filterOptionsForObjClass(IconObjectId),
        [ImageObjectId]: filterOptionsForObjClass(ImageObjectId),
        [VideoObjectId]: filterOptionsForObjClass(VideoObjectId),
        [RedirectObjectId]: filterOptionsForObjClass(RedirectObjectId),
        Pages: {
          title: 'Pages',
          icon: 'collection',
          field: '_objClass',
          value: PAGES,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options: PAGES.reduce((result: any, value: any) => {
            result[value] = filterOptionsForObjClass(value);
            return result;
          }, {}),
        },
        Files: {
          title: 'Files',
          icon: 'folder',
          field: '_objClass',
          value: DownloadObjectId,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options: FILES.reduce((result: any, value: any) => {
            result[value] = filterOptionsForContentType(value);
            return result;
          }, {}),
        },
      },
    },
    _modification: {
      title: 'Changed',
      type: 'checkbox',
      expanded: true,
      field: '_modification',
      options: {
        New: {
          value: 'new',
        },
        Edited: {
          value: 'edited',
        },
      },
    },
  };
}

/**
 * This function creates the filter options for a given object class
 * They are read out of a predefined const array called FILTER_PRESENTATIONS
 * @param objClass
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterOptionsForObjClass(objClass: string): any {
  const index = objClass as keyof typeof FILTER_PRESENTATIONS;

  // Find our filter. If it doesn't exist, create a new default one
  const filterPresentation = FILTER_PRESENTATIONS[index] || {
    title: objClass,
    icon: 'question',
  };
  return { field: '_objClass', value: objClass, ...filterPresentation };
}

/**
 * This function creates the filter options for a given content type
 * They are read out of a predefined const array called FILTER_PRESENTATIONS
 * @param contentType
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterOptionsForContentType(contentType: string): any {
  const index = contentType.toUpperCase() as keyof typeof FILTER_PRESENTATIONS;

  // Find our filter. If it doesn't exist, create a new default one
  const filterPresentation = FILTER_PRESENTATIONS[index] || {
    title: contentType.toUpperCase() + ' Files',
    icon: 'question',
  };
  return { field: 'blob:contentType', operator: 'contains', value: contentType, ...filterPresentation };
}
