import * as Scrivito from 'scrivito';
import { LandingPage, LandingPageObjectClass } from './landing-page-class';
import { LandingPageTemplate } from '../../components/pages/landing-page-template/landing-page-template';
import { isPageVisible } from '../../utils/scrivito/schedule-validation';
import { LandingPageError404 } from '../../components/pages/landing-page-template/landing-page-template-error404';
import { BasePageAttributes } from '../base-page';

export const LandingPageComponent: React.FC<{ page: LandingPage }> = ({ page }) => {
  const editMode = Scrivito.isInPlaceEditingActive();
  const scheduleInfo = isPageVisible(page);

  if (!editMode && !scheduleInfo.visible) {
    return <LandingPageError404 />;
  }

  return (
    <LandingPageTemplate scheduleInfo={scheduleInfo}>
      <Scrivito.ContentTag content={page} attribute={BasePageAttributes.BODY} />
    </LandingPageTemplate>
  );
};

Scrivito.provideComponent(LandingPageObjectClass, LandingPageComponent);
